import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import HeaderSM from '../../assets/images/header-sm.png'
import HeaderMD from '../../assets/images/header-md.png'
import HeaderLG from '../../assets/images/header-lg.png'

export const ContentHeader = styled.section`
  background-image: url(${HeaderSM});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;

  @media ${device.tablet} {
    background-image: url(${HeaderMD});
    min-height: 436px;
  }

  @media ${device.desktopLG} {
    background-image: url(${HeaderLG});
    min-height: 534px;
  }

  @media ${device.desktopXL} {
    background-image: url(${HeaderLG});
    min-height: 815px;
    display: flex;
    align-items: center;
  }
`
