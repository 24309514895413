import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerConcursoSuperaPj: imageSharp(fluid: { originalName: { regex: "/banner-concurso-supera-pj/" }}) {
        fluid(maxWidth: 585, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      videoImg1: imageSharp(fluid: { originalName: { regex: "/video-img-1/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      videoImg2: imageSharp(fluid: { originalName: { regex: "/video-img-2/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      vantagensNaPalmaDaMao: imageSharp(fluid: { originalName: { regex: "/banner-vantagens-na-palma-da-mao/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
