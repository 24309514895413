import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import usePageQuery from '../../pageQuery'
import { ContentHeader } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type ConcursoSuperaPJProps = {
  setIsOpen: (isOpen: boolean) => void;
};

const ConcursoSuperaPJ = ({ setIsOpen }: ConcursoSuperaPJProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Em breve uma surpresa do Inter pra sua empresa',
    element_action: 'click button',
    element_name: 'Assine a lista VIP e fique por dentro',
  }

  return (
    <ContentHeader className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6 mt-4 pr-md-0'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-lg-60 mb-3 text-green--500'>Em breve uma surpresa do Inter pra sua empresa</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-lg-22 text-grayscale--500'>
              Inscreva-se e receba em primeira mão novidades sobre uma surpresa que o Inter + parceiros estão preparando para você. Você poderá aproveitar uma <strong>jornada educacional</strong> voltada para a sua empresa crescer cada vez mais.
            </p>

            <div className='text-center mt-4'>
              <button
                className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
                onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent(dataLayer)
                }}
                type='button'
              >
                Assine a lista VIP e fique por dentro
              </button>
            </div>
          </div>

          <div className='col-12 col-md-5 offset-md-1 mt-4'>
            <Img fluid={data.bannerConcursoSuperaPj.fluid} alt='Cliente Inter Empresas sorrindo enquanto mostra o seu cartão de crédito PJ.' />
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default ConcursoSuperaPJ
