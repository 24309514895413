import React from 'react'
import Img from 'gatsby-image'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import usePageQuery from '../../pageQuery'
import VantagensNaPalmaDaMaoJSON from '../../assets/data/vantagens-na-palma-da-mao.json'
import { Vantagens } from './style'

type VantagensType = {
  title: string;
}

const VantagensNaPalmaDaMao = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 order-md-last mt-md-5 pt-md-5'>
            <Img fluid={data.vantagensNaPalmaDaMao.fluid} alt='Cliente Inter Empresas sorrindo enquanto mostra o seu cartão de crédito PJ.' />
          </div>
          <div className='col-12 col-md-6 mb-3 mt-4 mb-lg-0'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-orange--extra mb-3'>Vantagens que você leva na palma da mão.</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-3'>PIX gratuito, programa de pontos cheio de vantagens, aumento do limite de crédito investindo no CDB e muito mais.</p>

            <div>
              {VantagensNaPalmaDaMaoJSON.map((item: VantagensType) => (
                <Vantagens key={item.title} className='d-flex pt-3 mb-2'>
                  <div className='mr-2'>
                    <OrangeIcon size='SM' color='#FF7A00' icon='arrow-right' />
                  </div>
                  <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0'>{item.title}</p>
                </Vantagens>
              ))}
            </div>

            <div className='text-center mt-4'>
              <a
                href='https://inter.co/empresas/conta-digital/pessoa-juridica/'
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Vantagens que você leva na palma da mão.',
                    element_action: 'click button',
                    element_name: 'Saiba mais',
                    redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
                  })
                }}
              >
                Saiba mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VantagensNaPalmaDaMao
