import React, { useState, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as URLS from 'src/config/api/Urls'

import ErrorImg from '../assets/images/error.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Success from '../assets/images/success.png'

import { Container, CloseButton, Checkbox } from './style'
import { sendCDPFormData } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'

type BaixarEbookFormProps = {
  closeModal: () => void;
}

interface IFormValues {
  nome: string;
  email: string;
  cnpj: string;
}

function ListaVipForm ({ closeModal }: BaixarEbookFormProps) {
  const { register, errors, handleSubmit, setValue, reset }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = [ {
      campanha: 'Campanha Supera PJ',
      nome: data.nome,
      email: data.email,
      CpfCnpj: data.cnpj.replace(/\D/g, ''),
      aceite: accept,
    } ]

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setSent(true)
      sendCDPFormData({ formName: 'Campanha Supera PJ', cpf: data.cnpj.replace(/\D/g, ''), email: data.email })
      sendDatalayerEvent({
        section: 'dobra_02',
        element_action: 'submit',
        element_name: 'Enviar',
        section_name: 'Em breve uma surpresa do Inter pra sua empresa',
        step: 'success',
      })
      reset()
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center error'>
          {closeButton}
          <div className='col-12 text-center'>
            <img src={ErrorImg} alt='Imagem de erro' />
            <h3 className='fs-20 lh-24 mb-3 mt-4 text-white'>Houve um problema por aqui</h3>

            <p className='text-white fs-18 lh-22 pt-3'>
              No momento não foi possível completar sua solicitação. Você pode tentar novamente mais tarde.
            </p>

            <button
              onClick={handleReturn}
              title='Clique aqui para voltar'
              className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
            >
              Entendi
            </button>

          </div>
        </Container>
      )}

      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center px-0'>
            <div className='col-12 mx-auto text-center mb-4'>
              <img src={Success} alt='Sucesso' className='sucesso' />
            </div>

            <p className='fs-24 lh-30 text-white fw-600 font-sora'>
              Seu cadastro foi concluído com sucesso
            </p>

            <p className='fs-18 lh-22 fs-md-16 lh-md-19 text-white text-center'>
              Em breve você receberá em seu e-mail informações sobre uma surpresa do Inter para você.
            </p>

            <button
              onClick={handleReturn}
              title='Clique aqui para voltar'
              className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
            >
              Entendi
            </button>

          </div>
        </Container>
      )}

      <Container
        isModal={closeModal}
        className={`${error || sent ? 'd-none' : 'd-flex'} align-items-md-center align-items-lg-start`}
      >
        {closeButton}
        <form name='open_account' onSubmit={handleSubmit(sendForm)}>
          <div className='form--default'>
            <div className='row'>
              <div className='col-12 pt-3 pb-4 mb-2'>
                <h3 className='fs-32 lh-40 text-white mb-3'>
                  Cadastre-se e fique por dentro da novidade
                </h3>
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.nome && 'error'}`}>
                <label htmlFor='nome' className='fs-14 lh-17 text-white'>Nome</label>
                <input
                  ref={register({
                    required: 'Nome obrigatório',
                  })}
                  name='nome'
                  id='nome'
                  type='text'
                  maxLength={100}
                  placeholder='Digite o seu nome'
                />
                {errors.nome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.nome.message}</p>}
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.cnpj && 'input-error'}`}>
                <label htmlFor='cnpj' className='fs-14 lh-17 text-white'>CNPJ</label>
                <input
                  ref={register({
                    required: 'Digite um CNPJ válido',
                    validate: {
                      isCpf: (value: string) => Validations.cnpj(value) || 'CNPJ Inválido',
                    },
                  })}
                  name='cnpj'
                  id='cnpj'
                  type='tel'
                  placeholder='Digite o CNPJ da sua empresa'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cnpj', Masks.MaskCNPJ(event.currentTarget.value))}
                />
                {errors.cnpj && <p className='fs-12 text-right mb-0 '>{errors.cnpj.message}</p>}
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.email && 'error'}`}>
                <label htmlFor='email' className='fs-14 lh-17 text-white'>E-mail</label>
                <input
                  ref={register({
                    required: 'E-mail obrigatório',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Insira um e-mail válido',
                    },
                  })}
                  name='email'
                  id='email'
                  type='text'
                  placeholder='Digite o seu e-mail'
                />
                {errors.email && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.email.message}</p>}
              </div>

              <div className='col-12 d-flex flex-column'>
                <Checkbox onClick={() => setAccept(!accept)}>
                  <input
                    type='checkbox'
                    className='form-input-check'
                    checked={accept}
                  />
                  <label className='form-label-check fs-12 lh-15 pt-lg-1 text-white'>
                    Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a{' '}
                    <a
                      className='fw-700 text-orange--extra'
                      title='Acessar Política de Privacidade do Inter'
                      href='https://inter.co/politica-de-privacidade/privacidade/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Política de Privacidade
                    </a>.
                  </label>
                </Checkbox>
              </div>

              <div className='col-12 text-center mb-4 mb-md-0'>
                <button
                  type='submit' title='Enviar contato' disabled={!accept || loading}
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mb-4 mb-xl-0 mw-100'
                >
                  {loading ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

export default ListaVipForm
