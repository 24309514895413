import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OpenVideo from 'src/components/OpenVideo'

import { Section, VideoContainer, PlayContainer } from './style'
import usePageQuery from '../../pageQuery'
import playVideo from 'src/assets/images/shared/play-video-orange-no-text.png'

const SuperAppInterEmpresas = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='d-flex align-items-end align-items-md-start'>
      <div className='container py-5'>
        <div className='row'>
          <div className='d-none d-md-block col-md-6' />
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 mb-3 text-white'>Empreender <span className='d-xl-block'>pede coragem</span></h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
              No Mês do Empreendedor, o Inter homenageia aqueles que seguem seus sonhos e movimentam o país. Conheça algumas de suas histórias e celebre suas conquistas com a gente.
            </p>

          </div>

          <div className='col-12 col-md-6 mt-4 d-lg-flex justify-content-lg-end'>
            <VideoContainer>
              <Img fluid={data.videoImg1.fluid} alt='' />
              <OpenVideo
                link='https://www.youtube.com/embed/Ri2lJMFnXvc?si=knixNE0i2Aoi5i7P'
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click video',
                  section_name: 'Super App Inter Empresas',
                  element_name: 'Assistir Vídeo',
                  redirect_url: 'https://www.youtube.com/embed/Ri2lJMFnXvc?si=knixNE0i2Aoi5i7P',
                })
                }}
              >
                <PlayContainer className='d-flex flex-column align-items-center'>
                  <img src={playVideo} alt='Assistir Vídeo' width='45' height='45' className='mb-1' data-home-pj='depoimento' />
                </PlayContainer>
              </OpenVideo>
            </VideoContainer>
          </div>

          <div className='col-12 col-md-6 mt-4'>
            <VideoContainer>
              <Img fluid={data.videoImg2.fluid} alt='' />
              <OpenVideo
                link='https://www.youtube.com/embed/0lAELVr2e7g?si=L_w8Wh_ghqkPRsuI'
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click video',
                  section_name: 'Super App Inter Empresas',
                  element_name: 'Assistir Vídeo',
                  redirect_url: 'https://www.youtube.com/embed/0lAELVr2e7g?si=L_w8Wh_ghqkPRsuI',
                })
                }}
              >
                <PlayContainer className='d-flex flex-column align-items-center'>
                  <img src={playVideo} alt='Assistir Vídeo' width='45' height='45' className='mb-1' data-home-pj='depoimento' />
                </PlayContainer>
              </OpenVideo>
            </VideoContainer>
          </div>
        </div>
      </div>
    </Section>
 )
}

export default SuperAppInterEmpresas
