import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import HeaderSM from '../../assets/images/background-primeiro-super-app-sm.png'
import HeaderMD from '../../assets/images/background-primeiro-super-app-md.png'
import HeaderLG from '../../assets/images/background-primeiro-super-app-lg.png'
import HeaderXL from '../../assets/images/background-primeiro-super-app-xl.png'

export const Section = styled.section`
  background-image: url(${HeaderSM});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 418px;

  @media ${device.tablet} {
    background-image: url(${HeaderMD});
    min-height: 576px;
  }

  @media ${device.desktopLG} {
    background-image: url(${HeaderLG});
    min-height: 578px;
  }

  @media ${device.desktopXL} {
    background-image: url(${HeaderXL});
    min-height: 690px;
  }

  @media ${device.desktopXXXL} {
    background-image: url(${HeaderXL});
    min-height: 940px;
  }
`

export const Vantagens = styled.div`
  svg {
    vertical-align: super;
  }
`
