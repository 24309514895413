import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Vantagens, Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OPrimeiroSuperAppJSON from '../../assets/data/o-primeiro-super-app.json'

type OPrimeiroSuperAppType = {
  title: string;
}

const OPrimeiroSuperApp = () => {
    const [ sendDatalayerEvent ] = useDataLayer()

    return (
      <Section className='d-flex align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 fw-600 mb-3 text-white'>O primeiro Super App PJ do Brasil tem tudo o que você precisa!</h2>

              <div>
                {OPrimeiroSuperAppJSON.map((item: OPrimeiroSuperAppType) => (
                  <Vantagens key={item.title} className='d-flex'>
                    <div className='mr-2'>
                      <OrangeIcon size='SM' color='#FF7A00' icon='arrow-right' />
                    </div>
                    <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-0'>{item.title}</p>
                  </Vantagens>
                ))}
              </div>

              <div className='text-center mt-3'>
                <a
                  href='https://intergo.app/b4edb8d8'
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      section_name: 'O primeiro Super App PJ do Brasil tem tudo o que você precisa!',
                      element_action: 'click button',
                      element_name: 'Baixe o App',
                      redirect_url: 'https://intergo.app/b4edb8d8',
                      })
                  }}
                >
                  Baixe o App
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default OPrimeiroSuperApp
