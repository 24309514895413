import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import ListaVipForm from './components/form/_index'
import Header from './sections/header/_index'
import ConcursoSuperaPJ from './sections/concurso-supera-pj/_index'
import SuperAppInterEmpresas from './sections/super-app-inter-empresas/_index'
import VantagensNaPalmaDaMao from './sections/vantagens-na-palma-da-mao/_index'
import OPrimeiroSuperApp from './sections/o-primeiro-super-app/_index'
import pageContext from './pageContext.json'
import { Wrapper } from './style'
import useUtms from 'src/hooks/useUtms'
import useDomReady from 'src/hooks/window/useDomReady'

function ConsignadoPrivado () {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'Campanha Supera PJ' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  const baixeOLivroModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <ListaVipForm closeModal={() => setIsOpen(false)} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
        {baixeOLivroModal}
        <SuperAppInterEmpresas />
        <ConcursoSuperaPJ setIsOpen={setIsOpen} />
        <Header />
        <VantagensNaPalmaDaMao />
        <OPrimeiroSuperApp />
      </Layout>
    </Wrapper>
  )
}

export default ConsignadoPrivado
