import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import HeaderSM from '../../assets/images/background-super-app-sm.png'
import HeaderMD from '../../assets/images/background-super-app-md.png'
import HeaderLG from '../../assets/images/background-super-app-lg.png'
import HeaderXL from '../../assets/images/background-super-app-xl.png'

export const Section = styled.section`
  background-image: url(${HeaderSM});
  background-repeat: no-repeat;
  background-size: cover;
  height: 857px;

  @media ${device.tablet} {
    background-image: url(${HeaderMD});
    height: auto;
  }

  @media ${device.desktopLG} {
    background-image: url(${HeaderLG});
  }

  @media ${device.desktopXL} {
    background-image: url(${HeaderXL});
  }

  @media ${device.desktopXXL} {
    background-image: url(${HeaderXL});
    background-color: #07605B;
    background-size: auto;
    background-position-x: center;
    min-height: 704px;
  }
`

export const VideoContainer = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 320px;
      border-radius: 22px;
      height: 280px;
    }

    @media ${device.desktopLG} {
      width: 453px;
      height: 304px;
    }
  }

  @media ${device.desktopLG} {
    width: 453px;
    height: 304px;
  }
`

export const PlayContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    @media ${device.desktopLG} {
      width: 60px;
      height: 60px;
    }

    @media ${device.desktopXL} {
      width: 80px;
      height: 80px;
    }
  }
`
