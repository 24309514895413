import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ContentHeader } from './style'

const Header = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

    return (
      <ContentHeader role='img' aria-label='Empreendedor apoiado no balcão do seu comércio. Em segundo plano, vemos a maquininha de cartão Granito.'>
        <div className='container py-5'>
          <div className='row flex-column justify-content-between'>
            <div className='col-12 col-md-6 col-xl-7 pr-lg-0 pr-xl-3'>
              <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-48 lh-lg-60 fs-xl-72 lh-xl-90 mb-3 mb-md-2 mb-lg-3 mt-5 fw-600 text-white'>
                Dia da Coragem <span className='d-block'>Empreendedora</span>
              </h1>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
                Empreender no Brasil é superar desafios. Todos os anos, milhares de empresas fecham as portas.
              </p>

              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-4'>
                Vamos reconhecer o Dia da Coragem também como Dia da Coragem Empreendedora e homenagear quem mantém vivo o sonho do próprio negócio.
              </p>
            </div>
            <div className='col-12 col-md-6'>
              <a
                href='https://chng.it/npjZPLgHSX'
                target='_blank'
                rel='noreferrer'
                className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none text-md-center mw-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Dia da Coragem Empreendedora',
                    element_action: 'click button',
                    element_name: 'Confira o abaixo-assinado',
                    redirect_url: 'https://chng.it/npjZPLgHSX',
                    })
                }}
              >
                Confira o abaixo-assinado
              </a>
            </div>
          </div>
        </div>
      </ContentHeader>
    )
}

export default Header
