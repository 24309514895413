import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundSM from '../../assets/images/background-sm.png'
import BackgroundXL from '../../assets/images/background-xl.png'

export const ContentHeader = styled.section`
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100%;
  background-size: 140px;
  background-position-x: right;
  background-position-y: bottom;

  @media ${device.tablet} {
    background-size: 170px;
    background-position-y: 207px;
  }

  @media ${device.desktopLG} {
    background-size: 196px;
    background-position-y: 261px;
  }

  @media ${device.desktopXL} {
    height: 511px;
    background-image: url(${BackgroundXL});
    background-size: 290px;
    background-position-y: 289px;
  }

  @media ${device.desktopXXXL} {
    height: auto;
    background-image: url(${BackgroundXL});
    background-size: 405px;
    background-position-y: bottom;
  }
`
